.container {
  display: flex;
}

.iframe {
    height: calc(100vh);
    width: 100%;
    display: inline-block;
}

.gutter {
    background-color: black;
  }
  
  .gutter-horizontal {
    cursor: ew-resize;
  }

  .split {
    display: flex;
    width: 100%;
  }

  .dragger {
    margin-top: calc(50vh);
    position: absolute;
    z-index: 10;
  }

  