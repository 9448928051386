.navbar{
    background-color:#060b26 ;
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.menu-bars{
    margin-left: 1rem;
    font-size: 2rem;
    padding-top: 10px;
    background: none;
}

.nav-menu{
    background-color: #060b26;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top:0;
    left:-100%;
    transition: 850ms;
}

.nav-menu.active{
    left:0;
    transition: 350ms;
}

.nav-text{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a{
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width:95%;
    height:100%;
    display: flex;
    align-items: center;
}

.nav-text a:hover{
    background-color: #1a83ff;

}

.nav-menu-items{
    width:100%;
}

.navbar-toggle{
    background-color: #060b26;
    width:100%;
    height:80px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.title{
    text-align: center;
    margin-left: 40%;
    font-size: 30px;
    color: white;
}
span{
    margin-left: 16px;
     
}