p.thick {
    font-weight: bold;
  }
  /* a:link {
    color: blue;
    background-color: transparent;
    text-decoration: none;
  }
  a:hover {
    color: red;
    background-color: transparent;
    text-decoration: underline;
  } */