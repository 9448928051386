.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 5px;
    padding: 0;
    background-color: black;
    text-align: center;
    color: white;
}
.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    padding: 6px 25px;
    cursor: pointer;
}