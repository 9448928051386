@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(248, 246, 253);
} */

body,
input,
textarea,
button {
  font-family: "Inter", sans-serif;
}


.app {
  height:120vh;
  background-color: rgb(248, 246, 253);
  width: 500px;
  /* border: 15px solid green; */
  padding: 50px;
  margin: 0 auto;
}



/* Contact.js */
.form {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form > h1 {
  margin-bottom: 30px;
}

.form > input,
textarea {
  padding: 20px;
  border-radius: 3px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 20px;
  border: 1px solid lightgray;
  /* border: none; */
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
}

.form > input:focus,
textarea:focus {
  border: 1px solid rgb(0, 0, 196);
}

.form > textarea {
  height: 150px;
  max-width: 400px;
  min-height: 100px;
}

.form > label {
  padding-bottom: 10px;
  color: rgb(0, 0, 32);
  font-weight: bold;
}

.form > button {
  padding: 20px;
  border: none;
  background-color: rgb(2, 2, 110);
  font-weight: 500;
  font-size: 20px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
}

.form > button:hover {
  background-color: rgb(0, 0, 196);
}

.form > p{
    color:tomato;
}
